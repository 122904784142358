import { loadScript } from '@/helpers/utils'

export async function lottieLoadAnimation(args, speed) {
	if (!window.lottie) {
		await loadScript('https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.12.2/lottie_light.min.js')
	}

	const animation = lottie.loadAnimation(args)

	if (speed) {
		animation.setSpeed(speed)
	}
}
