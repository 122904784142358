<template>
	<div class="landing-age-selection">
		<div class="container-fluid">
			<div class="landing-age-selection__header">
				<img class="landing-age-selection__header-logo" :src="logoSrc" alt="" />
			</div>
			<div
				class="landing-age-selection__title landing-age-selection__title_uppercase"
				:class="{
					'landing-age-selection__title_size-m': AREA.CHAIR_WORKOUTS_MALE,
				}"
				v-html="content.title"
			></div>
			<div class="landing-age-selection__subtitle" v-html="content.subtitle"></div>
			<div class="landing-age-selection__grid">
				<div
					class="landing-age-selection__age-item"
					v-for="ageRange in ageRanges"
					:key="ageRange.value"
					:class="{ active: ageRange.value === activeValue }"
					@click="onClick(ageRange)"
				>
					<img class="landing-age-selection__age-image" :src="ageRange.img" alt="" />
					<div class="landing-age-selection__age-item-top"></div>
					<div class="landing-age-selection__age-item-bottom">
						<div class="landing-age-selection__age-item-text">{{ ageRange.label }}</div>
						<div class="landing-age-selection__age-item-icon">
							<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="12.1016" cy="12" r="10" fill="white" />
								<path
									d="M16.0736 12.0001C16.0736 12.4801 15.8836 12.9301 15.5436 13.2701L11.1736 17.6501C10.7036 18.1201 9.94359 18.1201 9.47359 17.6501C9.00359 17.1801 9.00359 16.4201 9.47359 15.9501L13.2136 12.2101C13.3336 12.0901 13.3336 11.9001 13.2136 11.7901L9.47359 8.05006C9.00359 7.58006 9.00359 6.82006 9.47359 6.35006C9.94359 5.88006 10.7036 5.88006 11.1736 6.35006L15.5436 10.7301C15.8836 11.0701 16.0736 11.5201 16.0736 12.0001Z"
									fill="#7D8BF7"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div v-if="otherAgesButton" class="landing-age-selection__other-ages">
				<button
					@click="onClick(otherAgesButton.data)"
					class="landing-age-selection__other-ages-button btn"
					type="button"
				>
					<span>{{ otherAgesButton.text }}</span>
					<i class="icon-arrow-right"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import OpenTelemetry from '@/modules/OpenTelemetry'
import { mapGetters, mapMutations } from 'vuex'
import { mergeTranslationsWithFallback } from '@/i18n'

import ageImg18 from '@/assets/landing-age-selection/age-18.webp'
import ageImg30 from '@/assets/landing-age-selection/age-30.webp'
import ageImg40 from '@/assets/landing-age-selection/age-40.webp'
import ageImg50 from '@/assets/landing-age-selection/age-50.webp'
import maleAge40 from '@/assets/landing-age-selection/male-40-49.webp'
import maleAge50 from '@/assets/landing-age-selection/male-50-59.webp'
import maleAge60 from '@/assets/landing-age-selection/male-60-69.webp'
import maleAge70 from '@/assets/landing-age-selection/male-70-80.webp'
import mainAge40 from '@/assets/landing-age-selection/main-40-49.webp'
import mainAge50 from '@/assets/landing-age-selection/main-50-59.webp'
import mainAge60 from '@/assets/landing-age-selection/main-60-69.webp'
import mainAge70 from '@/assets/landing-age-selection/main-70-80.webp'

import workouts18 from '@/assets/landing-age-selection/workouts-18-29.webp'
import workouts30 from '@/assets/landing-age-selection/workouts-30-39.webp'

export default {
	name: 'LandingAgeSelection',
	data() {
		return {
			clicked: false,
			activeValue: null,
		}
	},

	computed: {
		...mapGetters({
			propertyValue: 'survey/getPropertyValue',
		}),

		logoSrc() {
			if (this.IS_DARK_THEME) {
				return require('@/assets/logos/logo-white-inline.svg')
			}

			return require('@/assets/logos/logo-gray-inline.svg')
		},

		otherAgesButton() {
			if (
				[
					this.AREA.WALKING_MAIN,
					this.AREA.YOGA_MAIN,
					this.AREA.WALKING_MALE,
					this.AREA.YOGA_MALE,
					this.AREA.CHAIR_WORKOUTS_MALE,
				].some(Boolean)
			) {
				return {
					data: { value: 18, analyticValue: '18-39' },
					text: this.$t('female_yoga.pages.landing.im_young'),
				}
			}

			return null
		},

		ageRanges() {
			if ([this.AREA.WALKING_MAIN, this.AREA.YOGA_MAIN].some(Boolean)) {
				return [
					{
						value: 40,
						analyticValue: '40-49',
						label: this.$t('female_yoga.pages.landing.age_range.40_49'),
						img: mainAge40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: this.$t('female_yoga.pages.landing.age_range.50_59'),
						img: mainAge50,
					},
					{
						value: 60,
						analyticValue: '60-69',
						label: this.$t('female_yoga.pages.landing.age_range.60_69'),
						img: mainAge60,
					},
					{
						value: 70,
						analyticValue: '70+',
						label: this.$t('female_yoga.pages.landing.age_range.70_80'),
						img: mainAge70,
					},
				]
			} else if ([this.AREA.WALKING_MALE, this.AREA.YOGA_MALE, this.AREA.CHAIR_WORKOUTS_MALE].some(Boolean)) {
				return [
					{
						value: 40,
						analyticValue: '40-49',
						label: this.$t('female_yoga.pages.landing.age_range.40_49'),
						img: maleAge40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: this.$t('female_yoga.pages.landing.age_range.50_59'),
						img: maleAge50,
					},
					{
						value: 60,
						analyticValue: '60-69',
						label: this.$t('female_yoga.pages.landing.age_range.60_69'),
						img: maleAge60,
					},
					{
						value: 70,
						analyticValue: '70+',
						label: this.$t('female_yoga.pages.landing.age_range.70_80'),
						img: maleAge70,
					},
				]
			} else if ([this.AREA.MAIN_PILATES].some(Boolean)) {
				return [
					{
						value: 18,
						analyticValue: '18-29',
						label: this.content.picker.age_18_29,
						img: workouts18,
					},
					{
						value: 30,
						analyticValue: '30-39',
						label: this.content.picker.age_30_39,
						img: workouts30,
					},
					{
						value: 40,
						analyticValue: '40-49',
						label: this.content.picker.age_40_49,
						img: ageImg40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: this.content.picker.age_50,
						img: mainAge50,
					},
				]
			}

			return [
				{
					value: 18,
					analyticValue: '18-29',
					label: this.content.picker.age_18_29,
					img: ageImg18,
				},
				{
					value: 30,
					analyticValue: '30-39',
					label: this.content.picker.age_30_39,
					img: ageImg30,
				},
				{
					value: 40,
					analyticValue: '40-49',
					label: this.content.picker.age_40_49,
					img: ageImg40,
				},
				{
					value: 50,
					analyticValue: '50-59',
					label: this.content.picker.age_50,
					img: ageImg50,
				},
			]
		},

		nextRoute() {
			if (this.AREA.ACTIVITY_MAIN) {
				return 'activity-survey'
			} else if (this.AREA.WALKING_MAIN) {
				return 'walking-survey'
			} else if (this.AREA.YOGA_MAIN) {
				return 'yoga-survey'
			} else if (this.AREA.YOGA_MALE) {
				return 'male-activity-chair-yoga'
			} else if (this.AREA.CHAIR_WORKOUTS_MALE) {
				return 'male-activity-chair-workouts'
			} else if (this.AREA.MAIN_PILATES) {
				return 'pilates-survey'
			}

			return this.MAIN_FUNNEL_ROUTE_NAME
		},

		title() {
			if ([this.AREA.WALKING_MAIN, this.AREA.WALKING_MALE].some(Boolean)) {
				return this.$t('female_walking.pages.landing.step_1.title')
			} else if ([this.AREA.YOGA_MAIN, this.AREA.YOGA_MALE].some(Boolean)) {
				return this.$t('male_yoga.landing.title')
			} else if (this.AREA.CHAIR_WORKOUTS_MALE) {
				return '28-DAY CHAIR WORKOUT CHALLENGE'
			} else if (this.AREA.MAIN_PILATES) {
				return '28-DAY CHAIR PILATES CHALLENGE'
			}
			return this.$t('female_activity.pages.landing.step_1.title')
		},

		subtitle() {
			return this.$t('female_activity.pages.landing.step_1.subtitle', {
				age: `<b>${this.$t('female_activity.pages.landing.step_1.age')}</b>`,
			})
		},

		content() {
			return mergeTranslationsWithFallback({
				type: 'age_selection',
				key: this.getQueryParam('lpid'),
				fallback: {
					title: this.title,
					subtitle: this.subtitle,
					picker: {
						age_18_29: this.$t('female_activity.pages.landing.step_1.options.18_29'),
						age_30_39: this.$t('female_activity.pages.landing.step_1.options.30_39'),
						age_40_49: this.$t('female_activity.pages.landing.step_1.options.40_49'),
						age_50: this.$t('female_activity.pages.landing.step_1.options.50'),
					},
				},
			})
		},
	},

	methods: {
		...mapMutations({
			updateProperty: 'survey/updateProperty',
			updateSurveyModel: 'survey/updateSurveyModel',
		}),

		onClick(e) {
			if (this.clicked) {
				return
			}
			this.clicked = true
			this.activeValue = e.value
			setTimeout(() => {
				if (this.IS_WELCOME_LOADER_ALL_ACTIVITY_EXP) {
					this.$analytic.logEvent('OnboardingAgeRangeScreenDone').then(() => {
						this.$analytic.setUserProperties({ 'Age Range': e.analyticValue })
					})
				} else {
					this.$analytic.logEvent('OnboardingWelcomeScreenDone').then(() => {
						this.$analytic.setUserProperties({ 'Age Range': e.analyticValue })
						this.$analytic.logEvent('OnboardingAgeRangeScreenDone', { data: e.analyticValue })
					})
				}

				this.updateSurveyModel({
					...this.$store.state.survey.model,
					age: [e.value.toString()],
				})

				this.updateProperty({ property: 'age', value: e.value, isData: true })
				this.goToWelcome(e)
			}, 300)
		},

		goToWelcome() {
			OpenTelemetry.startSpan('vue-landing-to-onboarding', {
				attributes: {
					'span.type': 'vue-landing-to-onboarding',
				},
			})

			this.$router.push({ name: this.nextRoute })
			this.activeValue = null
			this.clicked = false
		},
	},

	mounted() {
		OpenTelemetry.endSpan('vue-landing-mounted')
	},
}
</script>

<style scoped lang="scss">
.landing-age-selection {
	padding-top: 0.8rem;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1.5rem;
		height: 21px;

		&-logo {
			height: 21px;
			width: 76px;
		}
	}

	&__title {
		font-size: var(--size-32);
		font-weight: 700;
		letter-spacing: -0.04rem;
		text-align: center;
		line-height: var(--size-38);
		margin-bottom: 1rem;

		&_size-m {
			font-size: var(--size-30);
			letter-spacing: -0.6px;
		}

		&_uppercase {
			text-transform: uppercase;
		}
	}

	&__subtitle {
		font-size: var(--size-18);
		font-style: normal;
		font-weight: 400;
		line-height: var(--size-22);
		margin-bottom: 1rem;
		text-align: center;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0.75rem;
	}

	&__age-item {
		height: 0;
		padding-bottom: 115.48%;
		position: relative;
		overflow: hidden;
		border-radius: 0 0 1rem 1rem;
		cursor: pointer;

		&.active {
			animation: click 0.2s ease-in-out;
		}
	}

	&__age-item-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0.7rem;
		background: linear-gradient(90deg, #586bff 6.87%, #8997ff 90.24%);
		font-weight: 600;
		line-height: 1.25;
		font-size: 1rem;
		color: #fff;
		z-index: 2;
	}

	&__age-item-top {
		position: absolute;
		top: 23%;
		left: 0;
		height: 87%;
		width: 100%;
		background: var(--Fill-Accent-16);
		border-radius: 47.6% 50.5% 0 0;
		z-index: 0;
	}

	&__age-image {
		position: absolute;
		pointer-events: none;
		z-index: 1;
		width: 100%;
		height: auto;
		left: 0;
		bottom: calc(1rem * 1.25 + 0.5rem * 2 + 0.25rem);
	}

	&__other-ages-button {
		font-size: var(--size-16);
		font-weight: 600;
		padding: 0;
		margin: 0;
	}

	&__other-ages {
		display: flex;
		justify-content: center;
		font-weight: 600;
		margin-top: 1.5rem;

		i {
			font-size: 0.75rem;
			margin-left: 0.4rem;
		}
	}
}

@keyframes click {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0px, 3px);
	}
	100% {
		transform: translate(0, 0);
	}
}
</style>
